import React, { useState, useEffect, useCallback, useMemo, memo } from "react";
import { Link } from "@quintype/components";
import { UpArrow, DownArrow } from "../Icons/Icon";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { BottomPromotionClaim } from "../../utils/gtm";
import { ResponsiveBannerImage } from "../../utils/responsive-image";
const BottomCollapse = ({ promo_data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const isSubscribedState = useSelector((state) => get(state, ["isSubscribedUser"], null));

  const getEmail = useCallback(() => {
    if (typeof window !== "undefined" && window.sessionStorage) {
      const storedUser = sessionStorage.getItem("SUBSCRIBED_USER");
      return storedUser ? JSON.parse(storedUser) : false;
    }
    return null;
  }, []);

  const isSubscribed = useMemo(
    () => (isSubscribedState === null ? getEmail() : isSubscribedState),
    [isSubscribedState, getEmail]
  );
  useEffect(() => {
    if (promo_data?.show_bottom_promo) {
      setTimeout(() => {
        setShow(true);
      }, 5000);
    }
  }, [promo_data]);

  const RenderArrow = () => {
    return isOpen ? <DownArrow /> : <UpArrow />;
  };

  return (
    <>
      {show && !isSubscribed ? (
        <div
          className={`${
            isOpen ? "promotion-expanded" : "promotion-collapsed"
          } promotion-wrapper fixed bottom-0 left-0 right-0 z-[10001] border-t-[1px] border-[#999] p-4 dark:bg-zinc-800 dark:text-[#f1f1f196] bg-[#f1f0ef]`}
        >
          {!isOpen ? (
            <div className="collapsed-content flex items-center">
              <p className="font-satoshi text-sm">
                {promo_data.collapsed.description}{" "}
                <Link
                  href={promo_data.collapsed.redirect_link}
                  target="_blank"
                  onClick={() => BottomPromotionClaim()}
                  className="font-semibold text-primary underline"
                >
                  {promo_data.collapsed.button.button_text}
                </Link>
              </p>
            </div>
          ) : (
            <div className="expanded-content font-satoshi" style={{ overflowY: "auto" }}>
              <div className="flex h-full w-full gap-6">
                <img
                  src={promo_data.expanded.image_key}
                  alt="promotion"
                  width={250}
                  height={250}
                  className="mobile:hidden"
                  style={{ height: "250px", width: "250px" }}
                />
                <div className="flex h-full flex-col justify-start pt-3">
                  <div className="flex flex-col">
                    <h2 className="font-satoshi text-[34px]">{promo_data.expanded.title}</h2>
                    <p className="text-md mb-[21px] mt-[10px]">{promo_data.expanded.description}</p>
                    <div className="flex flex-col">
                      <Link
                        href={promo_data.expanded.redirect_link}
                        target="_blank"
                        onClick={() => BottomPromotionClaim()}
                      >
                        <button className="bg-primary px-6 py-3 font-satoshi text-sm text-white">
                          {promo_data.expanded.button.button_text}
                        </button>
                      </Link>
                      {!getEmail ? (
                        <div className="text-md font-satoshi">
                          <p>
                            Already a subscriber?{" "}
                            <Link href={`/signin`} className="font-semibold text-primary underline">
                              Sign In
                            </Link>
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <button
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className="absolute right-4 top-4"
          >
            <RenderArrow />
          </button>
        </div>
      ) : null}
    </>
  );
};

export default BottomCollapse;

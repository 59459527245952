import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { TopBar } from "./top-bar";
import { Bottom } from "./bottom";
import { useSelector, useDispatch } from "react-redux";
import { AuthHeader } from "./auth-header";
import { checkAuthStatus } from "../../../../server/helpers/firebase";
import Ads from "../../ads/Ads";
import { adUnits } from "../../ads/ad-units.js";
import { getBookmarks, getPromoData, getBottomPromoData } from "../../../../api/utils";
import { BOOKMARK_DATA } from "../../../../isomorphic/components/store/actions";
import Promotionbox from "../../common/Promotionbox.js";
import BottomCollapse from "../../common/BottomCollapse";
import { SessionDataMang } from "../../common/SessionDataMang";
// Common wrapper for navigation. We could add OffcanvasMenu, Navbar etc components here.

export const Header = () => {
  const dispatch = useDispatch();
  const [promoData, setPromoData] = useState(null);
  const [bottomPromoData, setBottomPromoData] = useState(null);
  checkAuthStatus(dispatch);
  if (typeof window !== "undefined" && window.localStorage) {
    SessionDataMang(dispatch);
  }
  const getState = useSelector((state) => state);
  const user = get(getState, ["firebaseUser"], null);

  const bookmarksData = useSelector((state) => get(state, ["bookmarkData"], null));

  async function fetchAndDispatchBookmarks() {
    try {
      const bookmarks = await getBookmarks(user.email, 0);
      dispatch({
        type: BOOKMARK_DATA,
        bookmarkData: { bookmarks: bookmarks, bookmarkIds: bookmarks.map((item) => item.storyId) },
      });
    } catch (error) {
      console.error("Error fetching bookmarks:", error);
    }
  }

  useEffect(() => {
    if (!user) {
      checkAuthStatus(dispatch);
    } else if (bookmarksData === null) {
      fetchAndDispatchBookmarks();
    }
  }, [user, bookmarksData, dispatch]);

  useEffect(() => {
    const fetchPromoData = async () => {
      const promo_data = await getPromoData();
      const bottom_promo_data = await getBottomPromoData();
      setBottomPromoData(bottom_promo_data);
      setPromoData(promo_data);
    };

    fetchPromoData();
  }, []);

  const currentPath = get(getState, ["qt", "currentPath"], "");
  return currentPath === "/signin" || currentPath === "/signup" || currentPath.includes("/auth/") ? (
    <AuthHeader />
  ) : (
    <>
      <Ads adunit={adUnits["desktop-sp-top-970x90"]} />
      <div>
        {promoData ? <Promotionbox promo_data={promoData} /> : null}
        <TopBar />
        <Bottom />
      </div>

      <div>{bottomPromoData ? <BottomCollapse promo_data={bottomPromoData} /> : null}</div>
    </>
  );
};

import { STORY_FIELDS } from "../isomorphic/constants";
import axios from "axios";

export const getSearchPageItems = async (slug, offset, limit) => {
  const { results } = await (await fetch(`/api/v1/search?q=${slug}&offset=${offset}&limit=${limit + 1}`)).json();
  const loadMoreStories = results.stories.map((story) => {
    return { type: "story", story: story };
  });
  return loadMoreStories;
};

export const getStories = async (query, slug, offset, limit) => {
  const { stories } = await (
    await fetch(`/api/v1/stories?${query}=${slug}&offset=${offset}&limit=${limit + 1}`)
  ).json();
  const loadMoreStories = stories.map((story) => {
    return { type: "story", story: story };
  });
  return loadMoreStories;
};

export const getCollectionitems = async (slug, offset, limit) => {
  const { items } = await (
    await fetch(`/api/v1/collections/${slug}?&item-type=story&offset=${offset}&limit=${limit + 1}`)
  ).json();
  return items;
};

export const getAuthorStories = async (id, offset, limit) => {
  const { items } = await (
    await fetch(`/api/v1/authors/${id}/collection?&item-type=story&offset=${offset}&limit=${limit}`)
  ).json();
  return items;
};

export const loadRelatedStories = async (story, config) => {
  const { mountAt } = config || {};
  const mountAtPrefix = mountAt || "";
  const sectionQuery = story.sections && story.sections.length !== 0 ? `section-id=${story.sections[0].id}` : "";
  const response = await global
    .wretch(`${mountAtPrefix}/api/v1/stories/${story.id}/related-stories?${sectionQuery}`)
    .query({
      fields: STORY_FIELDS,
    })
    .get()
    .json((relatedStories) => relatedStories["related-stories"]);
  return response;
};
//swarajyanode-web.quintype.io/api/v1/story-collections/content?tag=monthly&sort-by=collection-date&limit=1
export const getCollections = async (param) => {
  try {
    let url = `/api/v1/collections/${param}`;
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    return data;
  } catch (error) {
    console.error(`Error while fetching data for collection ${param}`, error.message);
  }
};

export const getLatestMagazine = async () => {
  try {
    let url = `/api/v1/story-collections/content?tag=monthly&sort-by=collection-date&limit=1`;
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    return data;
  } catch (error) {
    console.error(`Error while fetching data for latest magazine`, error.message);
  }
};

export const getCollectionStories = async (collection_slug, offset) => {
  try {
    let url = `/api/v1/collections/${collection_slug}?offset=${offset}&limit=40`;
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    return data;
  } catch (error) {
    console.error(`Error while fetching data for collection ${collection_slug}`, error.message);
  }
};

export const getSubscribeBoxData = async () => {
  try {
    const url = `https://subsapi.swarajyamag.com/api/subscription-box?populate=*`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data.attributes;
  } catch (error) {
    console.error(error.message);
  }
};

// export const getBookmarks = async (email, offset) => {
//   try {
//     let url = `https://userstream.swarajyamag.com/api/bookmarks?email=${email}&offset=${offset}&limit=20&onlyArticle=true`;
//     const res = await fetch(url, {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer 9756c92fe386223d61e52f938896b91131ec1234b8fd8892d2e0d1ed574772529413148d9392519664ae1e4661c61b87a0dbd725249a4131e8c0382fa297b56ab3916c0069a48cd7d9f60ac80e2a99733a4204ba0a2867c949e450c39b2d6f7d386bf01611112a010c17d4e14a6b833cf1ee65fd5460bd08525e5f1ab005702a`,
//       },
//     });
//     const data = await res.json();
//     // console.log(data);
//     if (data.error) {
//       throw new Error(`Could not fetch getBookmark for email --> ${email}: ${data.error.message}`);
//     }
//     return data;
//   } catch (error) {
//     console.error(error.message);
//   }
// };

export const getBookmarks = async (email, offset) => {
  try {
    let url = `https://userstream.swarajyamag.com/api/bookmarks`;
    const res = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer 9756c92fe386223d61e52f938896b91131ec1234b8fd8892d2e0d1ed574772529413148d9392519664ae1e4661c61b87a0dbd725249a4131e8c0382fa297b56ab3916c0069a48cd7d9f60ac80e2a99733a4204ba0a2867c949e450c39b2d6f7d386bf01611112a010c17d4e14a6b833cf1ee65fd5460bd08525e5f1ab005702a`,
      },
      params: {
        email: email,
        offset: offset,
        limit: 20,
        onlyArticle: true,
      },
    });
    const data = res.data;
    // console.log(data);
    if (data.error) {
      throw new Error(`Could not fetch getBookmark for email --> ${email}: ${data.error.message}`);
    }
    return data;
  } catch (error) {
    console.error(error.message);
  }
};

export const addBookmark = async (bookmark_data) => {
  try {
    let url = `https://userstream.swarajyamag.com/api/bookmarks`;
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer 9756c92fe386223d61e52f938896b91131ec1234b8fd8892d2e0d1ed574772529413148d9392519664ae1e4661c61b87a0dbd725249a4131e8c0382fa297b56ab3916c0069a48cd7d9f60ac80e2a99733a4204ba0a2867c949e450c39b2d6f7d386bf01611112a010c17d4e14a6b833cf1ee65fd5460bd08525e5f1ab005702a`,
      },

      body: JSON.stringify(bookmark_data),
    });
    const data = await res.json();
    if (data.error) {
      throw new Error(`Could not Add Bookmark for email --> ${bookmark_data.email}: ${data.error.message}`);
    }
    return data.data;
  } catch (error) {
    console.error(error.message);
  }
};

export const deleteBookmark = async (email, id) => {
  try {
    let url = `https://userstream.swarajyamag.com/api/bookmarks/${id}?email=${email}`;
    const res = await fetch(url, {
      method: "DELETE",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer 9756c92fe386223d61e52f938896b91131ec1234b8fd8892d2e0d1ed574772529413148d9392519664ae1e4661c61b87a0dbd725249a4131e8c0382fa297b56ab3916c0069a48cd7d9f60ac80e2a99733a4204ba0a2867c949e450c39b2d6f7d386bf01611112a010c17d4e14a6b833cf1ee65fd5460bd08525e5f1ab005702a`,
      },
    });
    const data = await res.json();
    if (data.error) {
      throw new Error(`Could not Delete getBookmark id --> ${id} for email --> ${email}: ${data.error.message}`);
    }
    return data.data;
  } catch (error) {
    console.error(error.message);
  }
};

export const cdpEventHandler = async (data) => {
  try {
    let url = "/eventStream";
    const res = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer gPY8UcGdCmgPHXk73XV4X84Pj3fLVid8vXLjvJFiEN4z9vPZYvOcsTZEeKmDIlYgM`,
      },
    });

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getUserData = async (email) => {
  try {
    let url = `https://userstream.swarajyamag.com/api/readers?email=${email}`;
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer 9756c92fe386223d61e52f938896b91131ec1234b8fd8892d2e0d1ed574772529413148d9392519664ae1e4661c61b87a0dbd725249a4131e8c0382fa297b56ab3916c0069a48cd7d9f60ac80e2a99733a4204ba0a2867c949e450c39b2d6f7d386bf01611112a010c17d4e14a6b833cf1ee65fd5460bd08525e5f1ab005702a`,
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    if (res.status === 404) {
      return {
        profileId: null,
      };
    }
    return data;
  } catch (error) {
    console.error(error.message);
  }
};

export const getPromoData = async () => {
  try {
    let url = `https://subsapi.swarajyamag.com/api/promotion?populate=*`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data.attributes;
  } catch (error) {
    console.error(error.message);
  }
};

export const getBottomPromoData = async () => {
  try {
    let url = `https://subsapi.swarajyamag.com/api/promo-image?populate[collapsed][populate]=*&populate[expanded][populate]=*`;
    const res = await fetch(url, {
      next: {
        tags: ["promo-image", "all-fetch-revalidate"],
      },
    });
    const data = await res.json();
    if (data.error) {
      logger(url, data.error.message, res.status, "GET", {
        message: `Could not fetch  Bottom Promotion data: ${data.error.message}`,
      });
      throw new Error(`Could not fetch Bottom Promotion data: ${data.error.message}`);
    }
    return data.data.attributes;
  } catch (error) {
    console.error(error.message);
  }
};

import { ResponsiveImage, LazyLoadImages, ResponsiveHeroImage } from "@quintype/components";
import React from "react";
export const renderResponsiveImage = (slug, alt, defaultWidth = 100, quality = 75) => {
  return (
    <LazyLoadImages>
      <ResponsiveImage
        slug={slug}
        aspect-ratio={[3, 1]}
        defaultWidth={defaultWidth}
        imgParams={{ auto: ["format", "compress"], q: quality, fm: "webp" }}
        alt={alt}
      />
    </LazyLoadImages>
  );
};

export const ResponsiveBannerImage = ({ slug, alt, laoding = "lazy", quality = 75, width = "310" }) => {
  return (
    <div>
      <LazyLoadImages>
        <img
          src={`https://swarajya.gumlet.io/${slug}?w=${width}&q=${quality}&compress=true&format=auto`}
          alt={alt}
          loading={laoding}
          style={{
            aspectRatio: 3 / 1,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          width={"100"}
          height={"100"}
        />
      </LazyLoadImages>
    </div>
  );
};
